<template>
<a-layout>
    <a-layout-content>
        <a-row class="price" v-if="!_mobile" style="margin-top:160px;margin-bottom:60px">
            <a-col :span="20" :offset="2">
                <div style="display: table;margin: 0 auto;width:100%;min-width:1100px ">
                    <div class="s-price-font1"><span>가격</span></div>
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="스토어링크 멤버십 이용가격">

                            <div style="display:flex;margin-top:10px">
                                <div class="s-price-div2">

                                </div>
                                <div class="s-price-div1" v-bind:class="{ active: level==0}">
                                    이용중인 요금제
                                </div>
                                <div class="s-price-div1" v-bind:class="{ active: level==1}">
                                    이용중인 요금제
                                </div>
                                <div class="s-price-div1" v-bind:class="{ active: level==2}">
                                    이용중인 요금제
                                </div>
                                <div class="s-price-div1" v-bind:class="{ active: level==3}">
                                    이용중인 요금제
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div4">

                                </div>
                                <div class="s-price-div3" v-bind:class="[ level==0  ? 'active' : 'normal' ]">
                                    <div class="s-price-font2">Free</div>
                                    <div class="s-price-bar1 s-price-font3">무료(7일 체험판)</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font4">￦0</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(0)" v-bind:class="classObject1">서비스 신청</div>
                                </div>
                                <div class="s-price-div3 " v-bind:class="[ level==1  ? 'active' : 'normal' ]">
                                    <div class="s-price-font2">Standard</div>
                                    <div class="s-price-bar1 s-price-font3">스탠다드</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font5">월/</span>&nbsp;<span class="s-price-font4">￦9,900</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(1)" v-bind:class="classObject2">서비스 신청</div>

                                </div>
                                <div class="s-price-div3" v-bind:class="[ level==2  ? 'active' : 'normal' ]">
                                    <div class="s-price-font2">Premium</div>
                                    <div class="s-price-bar1 s-price-font3">프리미엄</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font5">월/</span>&nbsp;<span class="s-price-font4">￦29,000</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(2)" v-bind:class="classObject3">서비스 신청</div>

                                </div>
                                <div class="s-price-div3" v-bind:class="[ level==3  ? 'active' : 'normal' ]">
                                    <div class="s-price-font2">Enterprise</div>
                                    <div class="s-price-bar1 s-price-font3">엔터프라이즈</div>
                                    <div style="margin-top:20px;margin-bottom:38px"><span class="s-price-font6">별도협의</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(3)" v-bind:class="classObject4">서비스 신청</div>

                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal top">
                                    실시간 검색
                                </div>
                                <div class="s-price-div6 normal top">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal top">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal top">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal top">
                                    <img src="@/assets/images/check.png" />
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    캠페인 신청
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    캠페인 신청이력 / 리포트
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal" style="height:160px; padding-top: 65px;">
                                    키워드상세분석
                                </div>
                                <div class="s-price-div6 normal" style="height:160px">
                                    <div class="s-price-font7 ">
                                        네이버 키워드 검색량 조회<br />
                                        네이버 키워드 상세조회<br />
                                        상위노출 가능한 블로거 조회<br />
                                        네이버검색 파워링크(CPC) 단가 조회 <br />
                                        네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                    </div>
                                </div>
                                <div class="s-price-div6 normal" style="height:160px">
                                    <div class="s-price-font7 ">
                                        네이버 키워드 검색량 조회<br />
                                        네이버 키워드 상세조회<br />
                                        상위노출 가능한 블로거 조회<br />
                                        네이버검색 파워링크(CPC) 단가 조회 <br />
                                        네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                    </div>
                                </div>
                                <div class="s-price-div6 normal" style="height:160px">
                                    <div class="s-price-font7 ">
                                        네이버 키워드 검색량 조회<br />
                                        네이버 키워드 상세조회<br />
                                        상위노출 가능한 블로거 조회<br />
                                        네이버검색 파워링크(CPC) 단가 조회 <br />
                                        네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                    </div>
                                </div>
                                <div class="s-price-div6 normal" style="height:160px">
                                    <div class="s-price-font7 ">
                                        네이버 키워드 검색량 조회<br />
                                        네이버 키워드 상세조회<br />
                                        상위노출 가능한 블로거 조회<br />
                                        네이버검색 파워링크(CPC) 단가 조회 <br />
                                        네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                    </div>
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    상위노출분석
                                </div>
                                <div class="s-price-div6 normal">

                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    제품 등록
                                </div>
                                <div class="s-price-div6 normal">

                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">Max 100개 제품 등록 허용</div>
                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">Max 300개 제품 등록 허용</div>
                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">Max 500개 제품 등록 허용</div>
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    제품 순위 이력 차트
                                </div>
                                <div class="s-price-div6 normal">

                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                                <div class="s-price-div6 normal">
                                    <img src="@/assets/images/check.png" />
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div5 normal">
                                    제품 비교 검색
                                </div>
                                <div class="s-price-div6 normal">

                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">최근 6개월</div>
                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">최근 1년</div>
                                </div>
                                <div class="s-price-div6 normal">
                                    <div class="s-price-font8 " style="text-align:center">최근 3년</div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="상위노출 캠페인 이용가격">
                            <div style="display:flex;margin-top:10px">
                                <div class="s-price-div7 normal left top">
                                    구분
                                </div>
                                <div class="s-price-div7 normal top">
                                    스토어링크
                                </div>
                                <div class="s-price-div7 normal top">
                                    자사몰
                                </div>
                                <div class="s-price-div7 normal top">
                                    배달의 민족
                                </div>
                                <div class="s-price-div7 normal top">
                                    크라우드펀딩
                                </div>
                                <div class="s-price-div7 normal top" style="width:20%">
                                    방문체험단
                                </div>
                            </div>

                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    구매하기
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    7,000
                                </div>
                                <div class="s-price-div9" style="width:20%">
                                    네이버 예약 + <br />
                                    플레이스 저장 +<br />
                                    영수증 리뷰<br /><br />
                                    5팀 / 85,000<br />
                                    10팀 / 160,000<br />
                                    30팀 / 490,000<br />
                                    60팀 / 900,000<br />
                                    120팀 / 1,900,000
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    텍스트리뷰
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 right" style="width:20%">

                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    포토리뷰
                                </div>
                                <div class="s-price-div8 normal">
                                    2,000
                                </div>
                                <div class="s-price-div8 normal">
                                    2,000
                                </div>
                                <div class="s-price-div8 normal">
                                    2,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 right" style="width:20%">

                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    동영상리뷰(네이버)
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal right" style="width:20%">

                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    찜하기
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    Q&A
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    네이버블로그 포스팅
                                </div>
                                <div class="s-price-div8 normal">
                                    10,000
                                </div>
                                <div class="s-price-div8 normal">
                                    10,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    10,000
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    인스타그램 포스팅
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    5,000
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    회원가입
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    지지서명하기
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    2,000
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    응원리뷰
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    -
                                </div>
                                <div class="s-price-div8 normal">
                                    1,000
                                </div>
                                <div class="s-price-div8 normal" style="width:20%">
                                    -
                                </div>
                            </div>
                            <div style="display:flex">
                                <div class="s-price-div8 normal left">
                                    프리패스 쿠폰
                                </div>
                                <div class="s-price-div10">
                                    동영상리뷰, 네이버블로그, 인스타그램 제외하고 모두 무료
                                </div>
                            </div>

                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-col>
        </a-row>
        <a-row class="price" v-else style="margin:10px;margin-top:120px;">
            <a-col :span="24">
                <div style="margin: 0 auto;width:100% ">
                    <div class="s-price-m-font1"><span>가격</span></div>

                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="스토어링크 멤버십 이용가격">

                            <div class="s-price-div3-m" v-bind:class="[ level==0  ? 'active' : 'normal' ]">
                                <div class="s-price-div1-m" v-bind:class="{ active: level==0}">
                                    이용중인 요금제
                                </div>
                                <div style="text-align:center">
                                    <div class="s-price-font2">Free</div>
                                    <div class="s-price-bar1 s-price-font3">무료(7일 체험판)</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font4">￦0</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(0)" v-bind:class="classObject1">서비스 신청</div>

                                </div>
                                <a-row>
                                    <a-col :span="18" :offset="3" style="text-align:left;margin-bottom:20px">
                                        <div class="s-price-font7" style="margin-top:20px"><i class="far fa-check"></i>실시간 검색</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청이력 / 리포트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>키워드상세분석</div>
                                        <div class="s-price-font7 " style="padding-left:40px;">
                                            네이버 키워드 검색량 조회<br />
                                            네이버 키워드 상세조회<br />
                                            상위노출 가능한 블로거 조회<br />
                                            네이버검색 파워링크(CPC) 단가 조회 <br />
                                            네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                        </div>
                                    </a-col>

                                </a-row>
                            </div>
                            <div class="s-price-div3-m" v-bind:class="[ level==1  ? 'active' : 'normal' ]">
                                <div class="s-price-div1-m" v-if="level==1" v-bind:class="{ active: level==1}">
                                    이용중인 요금제
                                </div>
                                <div>
                                    <div class="s-price-font2">Standard</div>
                                    <div class="s-price-bar1 s-price-font3">스탠다드</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font5">월/</span>&nbsp;<span class="s-price-font4">￦9,900</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(1)" v-bind:class="classObject2">서비스 신청</div>

                                </div>
                                <a-row>
                                    <a-col :span="18" :offset="3" style="text-align:left;margin-bottom:20px">
                                        <div class="s-price-font7" style="margin-top:20px"><i class="far fa-check" style="color:#0264fb">&nbsp;&nbsp;</i>실시간 검색</div>
                                        <!-- <div class="s-price-font7"><i class="far fa-check" style="color:#0264fb">&nbsp;&nbsp;</i>키워드 분석</div> -->

                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청이력 / 리포트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>키워드상세분석</div>
                                        <div class="s-price-font7 " style="padding-left:40px">
                                            네이버 키워드 검색량 조회<br />
                                            네이버 키워드 상세조회<br />
                                            상위노출 가능한 블로거 조회<br />
                                            네이버검색 파워링크(CPC) 단가 조회 <br />
                                            네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                        </div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>상위노출분석</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 등록(Max 100개 제품 등록 허용)</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 순위 이력 차트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 비교 검색(최근 6개월)</div>
                                    </a-col>

                                </a-row>

                            </div>

                            <div class="s-price-div3-m" v-bind:class="[ level==2  ? 'active' : 'normal' ]">

                                <div class="s-price-div1-m" v-if="level==2" v-bind:class="{ active: level==2}">
                                    이용중인 요금제
                                </div>
                                <div>
                                    <div class="s-price-font2">Premium</div>
                                    <div class="s-price-bar1 s-price-font3">프리미엄</div>
                                    <div style="margin-top:20px;margin-bottom:30px"><span class="s-price-font5">월/</span>&nbsp;<span class="s-price-font4">￦29,000</span>&nbsp;<span class="s-price-font5">원</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(2)" v-bind:class="classObject3">서비스 신청</div>

                                </div>
                                <a-row>
                                    <a-col :span="18" :offset="3" style="text-align:left;margin-bottom:20px">
                                        <div class="s-price-font7" style="margin-top:20px"><i class="far fa-check"></i>실시간 검색</div>
                                        <!-- <div class="s-price-font7"><i class="far fa-check" style="color:#0264fb">&nbsp;&nbsp;</i>키워드 분석</div> -->

                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청이력 / 리포트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>키워드상세분석</div>
                                        <div class="s-price-font7 " style="padding-left:40px">
                                            네이버 키워드 검색량 조회<br />
                                            네이버 키워드 상세조회<br />
                                            상위노출 가능한 블로거 조회<br />
                                            네이버검색 파워링크(CPC) 단가 조회 <br />
                                            네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                        </div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>상위노출분석</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 등록(Max 300개 제품 등록 허용)</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 순위 이력 차트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 비교 검색(최근 1년)</div>
                                    </a-col>

                                </a-row>

                            </div>

                            <div class="s-price-div3-m" v-bind:class="[ level==3  ? 'active' : 'normal' ]">

                                <div class="s-price-div1-m" v-if="level==3" v-bind:class="{ active: level==3}">
                                    이용중인 요금제
                                </div>
                                <div>
                                    <div class="s-price-font2">Enterprise</div>
                                    <div class="s-price-bar1 s-price-font3">엔터프라이즈</div>
                                    <div style="margin-top:20px;margin-bottom:38px"><span class="s-price-font6">별도협의</span></div>
                                    <div class="s-price-btn1" @click="onUpgradeLevel(3)" v-bind:class="classObject4">서비스 신청</div>

                                </div>
                                <a-row>
                                    <a-col :span="18" :offset="3" style="text-align:left;margin-bottom:20px">
                                        <div class="s-price-font7" style="margin-top:20px"><i class="far fa-check"></i>실시간 검색</div>
                                        <!-- <div class="s-price-font7"><i class="far fa-check" style="color:#0264fb">&nbsp;&nbsp;</i>키워드 분석</div> -->

                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>캠페인 신청이력 / 리포트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>키워드상세분석</div>
                                        <div class="s-price-font7 " style="padding-left:40px">
                                            네이버 키워드 검색량 조회<br />
                                            네이버 키워드 상세조회<br />
                                            상위노출 가능한 블로거 조회<br />
                                            네이버검색 파워링크(CPC) 단가 조회 <br />
                                            네이버쇼핑 쇼핑검색(CPC) 단가 조회<br />
                                        </div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>상위노출분석</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 등록(Max 5신00개 제품 등록 허용)</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 순위 이력 차트</div>
                                        <div class="s-price-font7"><i class="far fa-check"></i>제품 비교 검색(최근 3년)</div>
                                    </a-col>

                                </a-row>

                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="상위노출 캠페인 이용가격">

                            <div class="s-price-div3-m">
                                <div style="text-align:center">
                                    <div class="s-price-bar1 s-price-font3">스토어링크</div>
                                </div>
                                <a-row>
                                    <a-col :span="22" :offset="1" style="text-align:center;margin-bottom:20px">
                                        <div class="s-price-font9" style="padding-top:20px">구매하기 [5,000원]</div>
                                        <div class="s-price-font9">텍스트리뷰 [1,000원]</div>
                                        <div class="s-price-font9">포토리뷰 [2,000원]</div>
                                        <div class="s-price-font9">동영상리뷰(네이버) [5,000원]</div>
                                        <div class="s-price-font9">찜하기 [1,000원]</div>
                                        <div class="s-price-font9">Q&A [1,000원]</div>
                                        <div class="s-price-font9">네이버블로그 포스팅 [10,000원]</div>
                                        <div class="s-price-font9">인스타그램 포스팅 [5,000원]</div>
                                    </a-col>

                                </a-row>
                            </div>

                            <div class="s-price-div3-m">
                                <div style="text-align:center">
                                    <div class="s-price-bar1 s-price-font3">자사몰</div>
                                </div>
                                <a-row>
                                    <a-col :span="22" :offset="1" style="text-align:center;margin-bottom:20px">
                                        <div class="s-price-font9" style="padding-top:20px">구매하기 [5,000원]</div>
                                        <div class="s-price-font9">텍스트리뷰 [1,000원]</div>
                                        <div class="s-price-font9">포토리뷰 [2,000원]</div>
                                        <div class="s-price-font9">회원가입 [1,000원]</div>
                                        <div class="s-price-font9">찜하기 [1,000원]</div>
                                        <div class="s-price-font9">Q&A [1,000원]</div>
                                        <div class="s-price-font9">네이버블로그 포스팅 [10,000원]</div>
                                        <div class="s-price-font9">인스타그램 포스팅 [5,000원]</div>
                                    </a-col>

                                </a-row>
                            </div>

                            <div class="s-price-div3-m">
                                <div style="text-align:center">
                                    <div class="s-price-bar1 s-price-font3">배달의 민족</div>
                                </div>
                                <a-row>
                                    <a-col :span="22" :offset="1" style="text-align:center;margin-bottom:20px">
                                        <div class="s-price-font9" style="padding-top:20px">구매하기 [5,000원]</div>
                                        <div class="s-price-font9">텍스트리뷰 [1,000원]</div>
                                        <div class="s-price-font9">포토리뷰 [2,000원]</div>
                                        <div class="s-price-font9">찜하기 [1,000원]</div>
                                        <div class="s-price-font9">네이버블로그 포스팅 [10,000원]</div>
                                        <div class="s-price-font9">인스타그램 포스팅 [5,000원]</div>
                                    </a-col>
                                </a-row>
                            </div>

                            <div class="s-price-div3-m">
                                <div style="text-align:center">
                                    <div class="s-price-bar1 s-price-font3">크라우드펀딩</div>
                                </div>
                                <a-row>
                                    <a-col :span="22" :offset="1" style="text-align:center;margin-bottom:20px">
                                        <div class="s-price-font9" style="padding-top:20px">구매하기 [7,000원]</div>
                                        <div class="s-price-font9">응원리뷰 [1,000원]</div>
                                        <div class="s-price-font9">지지서명하기 [2,000원]</div>
                                        <div class="s-price-font9">찜하기 [1,000원]</div>
                                        <div class="s-price-font9">네이버블로그 포스팅 [10,000원]</div>
                                        <div class="s-price-font9">인스타그램 포스팅 [5,000원]</div>
                                    </a-col>
                                </a-row>
                            </div>
                              <div class="s-price-div3-m">
                                <div style="text-align:center">
                                    <div class="s-price-bar1 s-price-font3">방문체험단</div>
                                </div>
                                <a-row>
                                    <a-col :span="22" :offset="1" style="text-align:center;margin-bottom:20px">
                                        <div class="s-price-font9" style="padding-top:20px">네이버예약 [17,000원]</div>
                                        <div class="s-price-font9">플레이스저장 [0원]</div>
                                        <div class="s-price-font9">영수증리뷰 [0원]</div>
                                    </a-col>
                                </a-row>
                            </div>



                        </a-tab-pane>
                    </a-tabs>

                </div>
            </a-col>
        </a-row>

    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

import {
  getLicense,
  replaceAll,
  getCurrentTime,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      level: 999
    };
  },
  mounted() {
    if (this.getUid) {
      this.level = getLicense();
    }
    document.getElementById("fullroot").scrollTop = 0;
    fbq("track", "PageView");
  },
  computed: {
    classObject1: function() {
      return {
        use: this.level == 0,
        ok: this.level == 1,
        not: this.level == 2 || this.level == 3
      };
    },
    classObject2: function() {
      return {
        ok: this.level == 0 || this.level == 2,
        use: this.level == 1,
        not: this.level == 3
      };
    },
    classObject3: function() {
      return {
        not: this.level == 0,
        ok: this.level == 1 || this.level == 3,
        use: this.level == 2
      };
    },
    classObject4: function() {
      return {
        not: this.level == 0 || this.level == 1,
        ok: this.level == 2,
        use: this.level == 3
      };
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  methods: {
    onUpgradeLevel(_level) {
      if (this.getUid) {
        if (_level != this.level && _level != 3) {
          this.$router.push({
            name: "setting",
            params: {
              level: _level
            }
          });
        } else {
          //not
        }
      } else {
        showError({
          notify: this.$vs.notify,
          msg: "로그인 후 사용이 가능합니다"
        });
      }
    }
  }
};
</script>

<style>
.s-price-div1 {
  width: 20%;
  height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #f8f8f8;
  text-align: center;
  padding-top: 19px;
}

.s-price-div1-m {
  width: 100%;
  height: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #f8f8f8;
  text-align: center;
  padding-top: 19px;
}

.s-price-div2 {
  width: 20%;
  height: 60px;
}

.s-price-div1.active,
.s-price-div1-m.active {
  background-color: #0264fb;
  color: #ffffff;
}

.s-price-div3 {
  width: 20%;
  /* width: 296px; */
  height: 269px;
  background-color: white;
  text-align: center;
}

.s-price-div3-m {
  width: 100%;
  background-color: white;
  text-align: center;
  margin-bottom: 30px;
}

.s-price-div3.normal {
  border-top: solid 1px #dbdbdb;
  border-right: solid 1px #dbdbdb;
}

.s-price-div3-m.normal {
  border: solid 1px #dbdbdb;
}

.s-price-div3-m.active {
  border: solid 1px #0264fb;
}

.s-price-div4 {
  width: 20%;
  /* width: 241px; */
  height: 269px;
  border-right: solid 1px #dbdbdb;
}

.s-price-div5 {
  /* width: 241px; */
  width: 20%;
  height: 60px;
  background-color: #ffffff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
  padding-top: 18px;
}

.s-price-div5.normal {
  border-left: solid 1px #dbdbdb;
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div5.top {
  border-top: solid 1px #dbdbdb;
}

.s-price-div7 {
  width: 16%;
  height: 60px;
  background-color: #0264fb;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  padding-top: 18px;
}

.s-price-div7.normal {
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div7.left {
  border-left: solid 1px #dbdbdb;
}

.s-price-div7.top {
  border-top: solid 1px #dbdbdb;
}

.s-price-div8 {
  width: 16%;
  padding-top: 18px;
  height: 60px;
  background-color: #ffffff;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
}

.s-price-div8.normal {
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div8.left {
  border-left: solid 1px #dbdbdb;
}

.s-price-div8.right {
  border-right: solid 1px #dbdbdb;
}

.s-price-div8.bottom {
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div8.top {
  border-top: solid 1px #dbdbdb;
}

.s-price-div9 {
  height: 240px;
  position: absolute;
  float: right;
  right: 0px;
  width: 20%;

  padding-top: 8px;
  background-color: #ffffff;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div10 {
  width: 84%;
  padding-top: 18px;
  height: 60px;
  background-color: #ffffff;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div6 {
  width: 20%;
  /* width: 296px; */
  height: 60px;
  background-color: #ffffff;
  text-align: center;
}

.s-price-div6 img {
  width: 20px;
  height: 20px;
  margin-top: 20px;
}

.s-price-div6.normal {
  border-right: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
}

.s-price-div6.top {
  border-top: solid 1px #dbdbdb;
}

.s-price-div3.active {
  border: solid 1px #0264fb;
}

.s-price-font1,
.s-price-m-font1 {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 51px;
}

.s-price-font1 span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  text-align: center;
  color: #0a367b;
  padding-bottom: 10px;
  border-bottom: 1px solid #0a367b;
}

.s-price-m-font1 span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  text-align: center;
  color: #0a367b;
  padding-bottom: 10px;
  border-bottom: 1px solid #0a367b;
}

.s-price-font2 {
  margin-top: 30px;
  font-family: "Muli";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #0264fb;
}

.s-price-font3 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-price-font4 {
  font-family: "Muli";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #0264fb;
}

.s-price-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.s-price-font6 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  color: #0264fb;
}

.s-price-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: left;
  letter-spacing: -0.88px;
  color: #7d7d7d;
  padding-top: 10px;
  padding-left: 20px;
}

.s-price-font8 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: left;
  letter-spacing: -0.88px;
  color: #7d7d7d;
  padding-top: 17px;
}

.s-price-font9 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: center;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-price-bar1 {
  border-bottom: 1px solid #d8d8d8;
  width: 120px;
  margin: 0 auto;
}

.s-price-btn1 {
  width: 90%;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #bfbfbf;
  margin: 0 auto;
  padding-top: 10px;
  cursor: pointer;
}

.s-price-btn1.use {
  background-color: #bfbfbf;
  color: white;
}

.s-price-btn1.ok {
  background-color: #0264fb;
  color: white;
  cursor: pointer;
}

.s-price-btn1.not {
  background-color: white;
  color: #0264fb;
}

.price .fa-check {
  color: #0264fb;
  padding-right: 5px;
}

.price .ant-tabs-tab-active,
.price .ant-tabs-tab:hover {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #0264fb !important;
}

.price .ant-tabs-tab {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #7d7d7d;
}

.price .ant-tabs-ink-bar {
  background-color: #0264fb;
  height: 4px;
}
</style>
